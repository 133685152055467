<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Liste des gains",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Liste des gains",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Gains",
          href: "/",
        },
        {
          text: "Liste des gains",
          active: true,
        },
      ],




      totalRows: 1,
      currentPage: 1,
      currentPageActif: 1,
      currentPagePasse: 1,
      perPage: 100,
      perPagePasse: 100,
      perPageActif: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterActif: null,
      filterPast: null,
      codeevent: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "receiver", sortable: true, label: "Bénéficiaire " },
        { key: "filleul", sortable: true, label: "Filleul" },
        { key: "dateGain", sortable: true, label: "Date" },
        { key: "montant", sortable: true, label: "Montant " },

        { key: "action" },
      ],


      startValue: 1,
      endValue: 500,
      parbon: 500,
      totalValueActif: 0,
      totalValuePasse: 0,
      userChargeLoader: false,

      recherche: "",
      listGainRejected: [],
      gainWattingList: [],
      listGainApprouve: [],
      showdisable: false,
      showDelete: false,
      showActive: false
    };
  },
  validations: {},

  mounted() {
    this.gainsAttenteList();
    this.gainsAccepted();
    this.gainsReject();

    setTimeout(() => {

      this.loadAttente = false
      this.loadActif = false
      this.loadPasse = false




    }, 3000);

  },
  methods: {

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },



    async gainsAttenteList() {
      this.loadActif = true
      const listeGains = await apiRequest(
        "GET",
        "gain-parrain/waiting",
        undefined,
        false
      );

      if (listeGains && listeGains.data) {
        const gainFormatTableActif = listeGains.data.map(
          (gaint) => {
            return {
              id: gaint.id,
              dateGain: new Date(gaint.dateGain).toLocaleString("fr-FR"),
              receiverId: gaint.user.personne.id,
              receiver: `${gaint.user.personne.firstname} ${gaint.user.personne.lastname}`,
              filleulId: gaint.filleul.personne.id,
              filleul: `${gaint.filleul.personne.firstname} ${gaint.filleul.personne.lastname}`,
              montant: gaint.montant

            };
          }
        );
        this.gainWattingList = gainFormatTableActif;

        this.totalValueActif = this.gainWattingList.length;
      }
    },


    async gainsAccepted() {
      this.loadAttente = true
      const listeGainsAccepted = await apiRequest(
        "GET",
        "gain-parrain/approuved",
        undefined,
        false
      );

      if (listeGainsAccepted && listeGainsAccepted.data) {
        const gainFormatTableActif = listeGainsAccepted.data.map(
          (gaint) => {
            return {
              id: gaint.id,
              dateGain: new Date(gaint.dateGain).toLocaleString("fr-FR"),
              receiver: `${gaint.user.personne.firstname} ${gaint.user.personne.lastname}`,
              filleul: `${gaint.filleul.personne.firstname} ${gaint.filleul.personne.lastname}`,
              montant: gaint.montant

            };
          }
        );
        this.listGainApprouve = gainFormatTableActif;

        this.totalValueActif = this.listGainApprouve.length;
      }
    },

    async gainsReject() {
      this.loadPasse = true
      const listeGainsRejected = await apiRequest(
        "GET",
        "gain-parrain/rejected",
        undefined,
        false
      );

      if (listeGainsRejected && listeGainsRejected.data) {
        const gainFormatTableRejected = listeGainsRejected.data.map(
          (gaint) => {
            return {
              id: gaint.id,
              dateGain: new Date(gaint.dateGain).toLocaleString("fr-FR"),
              receiver: `${gaint.user.personne.firstname} ${gaint.user.personne.lastname}`,
              filleul: `${gaint.filleul.personne.firstname} ${gaint.filleul.personne.lastname}`,
              montant: gaint.montant

            };
          }
        );
        this.listGainRejected = gainFormatTableRejected;

        this.totalValueActif = this.listGainRejected.length;
      }
    },






    onFilteredInactif(filteredItems) {
      this.totalValueInactif = filteredItems.length;
      this.currentPage = 1;
    },




    deactivateRow(row) {
      this.showDelete = true;
      this.currentEventId = row.id;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.currentEventId = row.id;
    },
    activeRow(row) {
      this.showActive = true;
      this.currentEventId = row.id;
    },
    closeModal() {
      this.showDelete = false;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },






    async deleteEvent() {
      this.showDelete = false;
      const update = await apiRequest(
        "POST",
        "gain-parrain/action",
        { id: this.currentEventId, status: "REJECTED" },
        false
      );

      if (update) {

        this.showDelete = false;
        this.$toast.success("Demande de gain rejetée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.gainsReject()
        this.gainsAttenteList();
        this.gainsAccepted()

      }
    },

    async activeevent() {
      this.showActive = false;
      const update = await apiRequest(
        "POST",
        "gain-parrain/action",
        { id: this.currentEventId, status: "APPROUVED" },
        false
      );

      if (update) {


        this.$toast.success("Demande de gain approuvée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.gainsReject()
        this.gainsAttenteList();
        this.gainsAccepted()

      }
    },

    /*
    async recharge() {
      this.showRecharge = false;
      const charge = await apiRequest(
        "POST",
        "parrain/recharge",
        { idUser: this.parrainRow.userId },
        false
      );
      if (charge && charge.data) {
        this.$toast.success("Recharge effectuée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
/*     changeGaingMembre() {
      this.showpopGainMembre = true;
      this.new_gain = this.gain_parrain_membre.membre;
    },
    changeGainParrain() {
      this.showpopGainParrain = true;
      this.new_parrain = this.gain_parrain_membre.parrain;
    },


    setEventAdress: function (place) {

      if (place) {       
        this.eventForm.adresseEvent = place.formatted_address;
        this.eventForm.longitude = place.geometry.location.lng();
        this.eventForm.latitude = place.geometry.location.lat();
      }
    }, */

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="loadAttente == false || loadActif == false || loadPasse == false">
            <span class="loading">Chargement en cours... </span>

          </div>

          <div class="card-body">
            <div class="row d-flex justify-content-between p-2">
              <div class="card-title">
                <!--     <h5>Gérer</h5> -->
              </div>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">En attente</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="gainWattingList
                      " :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFilteredInactif">
                      <template v-slot:cell(etat)="row">
                        <span v-if="row.item.etat == true" style="
                            padding: 0.25rem 1.2rem;
                            background-color: #4caf50;
                            border-radius: 1.3rem;
                            width: 100% !important;
                            height: 14px;
                            font-size: 13px;
                            color: #fff;
                          ">
                          Actif
                        </span>
                        <span v-if="row.item.etat == false" style="
                            padding: 0.25rem 0.89rem;
                            background-color: #ff5252;
                            border-radius: 1.3rem;
                            width: 100%;
                            height: 16px;
                            font-size: 13px;
                            color: #fff;
                          ">
                          Inactif
                        </span>
                      </template>
                      <template v-slot:cell(filleul)="row">

                        <div @click="goToUserDetail(row.item.filleulId)">
                          <span>
                            {{ row.item.filleul }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>
                      <template v-slot:cell(receiver)="row">

                        <div @click="goToUserDetail(row.item.receiverId)">
                          <span>
                            {{ row.item.receiver }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>
                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class="text-danger" @click="deactivateRow(row.item)"
                          v-b-tooltip.hover title="désactiver">
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-success" @click="activeRow(row.item)" v-b-tooltip.hover
                          title="activer">
                          <i class="fas fa-check font-size-15"></i>
                        </a>


                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPage" :total-rows="totalValueInactif" :per-page="perPage"
                            :aria-controls="trajet"></b-pagination>
                        </ul>
                      </div>
                      <!--  <transition mode="out-in">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                       
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button
                                class="myBouton"
                                @click="firstVal()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Prem"
                              >
                                <ion-icon
                                  name="play-skip-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                type="button"
                                class="myBouton"
                                @click="decrement()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Préc"
                              >
                                <ion-icon
                                  name="chevron-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="increment()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Suiv"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="chevron-forward-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="incrementToLast()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Dern"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="play-skip-forward-outline"
                                ></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition> -->
                    </div>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Approuvés</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterActif" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="listGainApprouve
                      " :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">

                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class="text-danger" @click="deactivateRow(row.item)"
                          v-b-tooltip.hover title="désactiver">
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>





                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPageActif" :total-rows="totalValueActif" :per-page="perPageActif"
                            :aria-controls="trajet"></b-pagination>
                        </ul>
                      </div>
                      <!--  <transition mode="out-in">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
           
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button
                                class="myBouton"
                                @click="firstVal()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Prem"
                              >
                                <ion-icon
                                  name="play-skip-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                type="button"
                                class="myBouton"
                                @click="decrement()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Préc"
                              >
                                <ion-icon
                                  name="chevron-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="increment()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Suiv"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="chevron-forward-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="incrementToLast()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Dern"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="play-skip-forward-outline"
                                ></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition> -->
                    </div>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Rejetés</a>
                  </template>

                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterPast" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="listGainRejected
                      " :fields="fields" responsive="sm" :per-page="perPagePasse" :current-page="currentPagePasse"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">

                      <template v-slot:cell(action)="row">
                        <a href="javascript:void(0);" class="text-success" @click="activeRow(row.item)" v-b-tooltip.hover
                          title="activer">
                          <i class="fas fa-check font-size-15"></i>
                        </a>






                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPagePasse" :total-rows="totalValuePasse" :per-page="perPagePasse"
                            :aria-controls="trajet"></b-pagination>
                        </ul>
                      </div>
                      <!--  <transition mode="out-in">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
           
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button
                                class="myBouton"
                                @click="firstVal()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Prem"
                              >
                                <ion-icon
                                  name="play-skip-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                type="button"
                                class="myBouton"
                                @click="decrement()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Préc"
                              >
                                <ion-icon
                                  name="chevron-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="increment()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Suiv"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="chevron-forward-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="incrementToLast()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Dern"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="play-skip-forward-outline"
                                ></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition> -->
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>




    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment rejeter cette demande de gain?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteEvent()">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showActive"
      title="Voulez-vous vraiment octroyer ce gain à l'utilisateur ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activeevent()">Oui</b-button>
      </div>
    </b-modal>



  </Layout>
</template>
<style >
.alignBtn {
  display: flex;
  float: right;

  align-items: flex-end;
}

.bg-secondary-special {
  background: #3aaa35;
}

.bg-secondary-smile {
  background: #3aaa35;
}

.widget-wrapping {
  display: flex !important;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.thesize {
  font-size: 2em;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.typeParrain {
  margin-top: 0.444rem;
  font-size: 17px;
  color: #fff;
}

.gain {
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.fa-edit {
  font-size: 17px;
  cursor: pointer;
}

thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;

}


.pac-container {
  z-index: 10000 !important;
}

.btn-Back {
  background: #3aaa35;
  color: #fff;
}


.modal-header {
  background: #3aaa35;

}


.modal-title {
  color: #fff;
}


.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}
</style>
